import Layout from '../components/Layout'
import Icon from '../components/Icon'
import { Link } from '@/components/Link/Link'

export default function Error404() {
  return (
    <Layout>
      <div className="container-padded">
        <div className="h-fit-screen flex flex-col justify-center items-center mt-14">
          <h1 className="flex items-baseline text-9xl font-bold">
            4<Icon name="record" className="w-24 h-24 ml-3 mr-2" />4
          </h1>
          <h2 className="text-4xl text-center mt-1.5">A keresett oldal nem található</h2>
          <p className="text-2xl text-center mt-5 max-w-lg">
            Lehetséges, hogy hibás linkre kattintottál, vagy rossz címet írtál be. Indíts{' '}
            <span className="underline">
              <Link href="/kereses">egy keresést</Link>
            </span>
            , vagy válassz a fenti kategóriákból!
          </p>
        </div>
      </div>
    </Layout>
  )
}
