import { ReactNode } from 'react'

import Icon from '../Icon'
import { getTypeIcon, getTypeString } from '../../utils'
import { useRouter } from 'next/router'
import { Theme } from 'pages/[...entity]'

interface ConnectionCount {
  type: string
  count: number
}

export type ConnectionsCount = ConnectionCount[]

interface QuickFactsProps {
  connectionsCount: ConnectionsCount
  onThemeChipClick: (value: Theme) => void
  elementsBefore?: ReactNode
}

const classes = {
  container: 'flex flex-column lg:items-center lg:text-xl xl:text-2xl',
  list: 'sm:block md:flex md:justify-evenly w-full',
  listItem: 'flex items-center my-5',
  factIcon: 'w-6 h-6 xl:w-8 xl:h-8 mr-4'
}

export default function QuickFacts({
  connectionsCount,
  onThemeChipClick,
  elementsBefore
}: QuickFactsProps) {
  const router = useRouter()
  const lang = router.query.lang

  return (
    <aside className={classes.container} id="quickfacts">
      <ul className={classes.list}>
        {elementsBefore || []}
        {connectionsCount &&
          connectionsCount.map(({ type, count }) => {
            const factName = lang === 'eng' ? type : getTypeString(type)?.toLowerCase()

            return (
              <li
                key={type}
                className={`${classes.listItem} cursor-pointer flex-col`}
                title={factName}
                onClick={() => onThemeChipClick(type as Theme)}
              >
                <div className="flex">
                  <Icon name={getTypeIcon(type)} className={classes.factIcon} disableHover />
                  <span className="font-bold">
                    <span>{count}</span>
                    <span className="md:hidden ml-2">{factName}</span>
                  </span>
                </div>
                <div className="hidden md:block">{factName}</div>
              </li>
            )
          })}
      </ul>
    </aside>
  )
}
