import { Reference } from '@mpe/api-client/models/entities/reference'
import { Connection } from '@mpe/api-client/models/connections/connection'

interface Props {
  references: Connection[]
  className: string
  label?: string
}

const EntityReferences = ({ references, className, label = 'Forrás:' }: Props) => {
  if (!references.length) {
    return null
  }

  return (
    <div className={className}>
      <h3>{label}</h3>
      <ul>
        {references.map((item: Connection, i) => {
          return (
            <li
              key={i}
              className="entity-reference-item"
              dangerouslySetInnerHTML={{
                __html: (item.toEntity as Reference).getHTMLFormat(
                  item.rightDescription || item.leftDescription,
                  true
                )
              }}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default EntityReferences
