import { useState } from 'react'

export interface IBreadcrumb {
  title?: string | null
  url: string
}

export default function useBreadcrumbs(title?: string | null, reset?: boolean) {
  if (typeof window === 'undefined') {
    return []
  }

  const [crumbs, setCrumbs] = useState<IBreadcrumb[] | undefined>(undefined)
  const url = window.location.href
  const toAdd = { title, url }

  if (typeof crumbs === 'undefined') {
    if (reset) {
      setCrumbs([toAdd])
      window.sessionStorage?.setItem('mpeBreadcrumbs', JSON.stringify([toAdd]))
    } else {
      // try from SessionStorage
      const storedCrumbs = window.sessionStorage?.getItem('mpeBreadcrumbs')

      if (storedCrumbs) {
        const parsedStoredCrumbs = JSON.parse(storedCrumbs)
        const lastPage = parsedStoredCrumbs[parsedStoredCrumbs.length - 1]
        const newCrumbs = lastPage.url === url ? parsedStoredCrumbs : [...parsedStoredCrumbs, toAdd]
        const truncatedCrumbs =
          newCrumbs.length > 5 ? newCrumbs.slice(newCrumbs.length - 5) : newCrumbs
        setCrumbs(truncatedCrumbs)
        window.sessionStorage?.setItem('mpeBreadcrumbs', JSON.stringify(truncatedCrumbs))
      } else {
        setCrumbs([toAdd])
        window.sessionStorage?.setItem('mpeBreadcrumbs', JSON.stringify([toAdd]))
      }
    }
  }

  return crumbs || []
}
