import { Entity } from '@mpe/api-client/models/entities/entity'

interface Props {
  entity: Entity<any>
  className: string
  label: string
  path: string
}

const EntityLinksList = ({ entity, label, path, className = '' }: Props) => {
  const list: any = (entity.data || {})[path as keyof typeof entity.data] || null
  if (!list) {
    return null
  }

  return (
    <div className={`entity-links-list ${className}`}>
      <h3>{label}</h3>
      <ul className="list-disc pl-6">
        {list.map((item: any, i: number) => {
          if (!item.url || item.url === '') return

          return (
            <li key={i} className="entity-link-item">
              <a
                target="_blank"
                href={item.url}
                className="border-b border-transparent hover:border-skyBlue hover:text-skyBlue"
              >
                {item.name || item.url}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default EntityLinksList
