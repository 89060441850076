import { useRouter } from 'next/router'
import Icon from '../Icon'
import { Link } from '../Link/Link'
import { SongLyricsProps, Song } from './types'
import useSongLyrics from './useSongLyrics'

export default function SongLyrics(props: SongLyricsProps) {
  const router = useRouter()
  const lang = router.query.lang

  const { classes, songs, url }: any = useSongLyrics(props)

  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>
        <h2 className="font-bold">{lang == 'eng' ? 'Lyrics' : 'Zeneszöveg'}</h2>
        <ul className={classes.lyrics}>
          {songs.map((song: Song, index: number) => {
            return (
              <li key={index} className={classes.item}>
                <Link
                  href={song.url}
                  className={classes.songTitle}
                  style={{ fontSize: '19px' }}
                  target={'_blank'}
                >
                  {song.title}
                </Link>
              </li>
            )
          })}
        </ul>
        <div className={classes.bottom}>
          <Link href={url} className={classes.more} target={'_blank'}>
            <span>{lang == 'eng' ? 'Go to Zeneszöveg.hu' : 'Tovább a Zeneszöveg.hu-ra'}</span>
            <Icon name="arrow-right" className={classes.moreIcon} />
          </Link>
        </div>
      </div>
    </div>
  )
}
