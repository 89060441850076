import { useEffect, useRef, useState } from 'react'
import { Entity } from '@mpe/api-client/models/entities/entity'
import { Connection } from '@mpe/api-client/models/connections/connection'

import EntityReferences from '@/components/EntityReferences'
import EntityLinksList from '@/components/EntityLinksList'
import { getStringAsRichText } from '@/utils'

import style from './DescriptionStyle.module.css'
import { useRouter } from 'next/router'
import { englishContent } from '@/consts/englishContent'

const customStyle = {
  notExpandedDescription: {
    height: '500px'
  },
  expandedDescription: {
    height: 'auto'
  }
}

const classes = {
  wrapper: 'p-4 md:pt-10 md:px-14 md:pb-6',
  descriptionContainer: 'relative overflow-hidden',
  leadText: `lg:w-3/4 text-lg md:text-xl text-white text-justify mx-auto ${style.richText}`,
  toggleBtn: 'my-5 text-center',
  toggleBtnText: 'border-b-2 text-2xl border-skyBlue cursor-pointer hover:text-skyBlue',
  bottomGradient:
    'bg-gradient-to-b from-transparent to-black h-80 absolute z-10 inset-x-0 bottom-0',
  references: `lg:w-3/4 text-md md:text-lg mx-auto mt-8`,
  links: `lg:w-3/4 text-md md:text-lg mx-auto mt-8`,
  updatedAt: `lg:w-3/4 text-md md:text-lg text-darkGray mx-auto mt-8`
}

interface DescriptionProps {
  entity: Entity<any>
  references: Connection[]
}

export default function Description({ entity, references }: DescriptionProps) {
  if (!entity.description || !entity.description.length) {
    return null
  }

  const router = useRouter()
  const lang = router.query.lang

  const threshold = 700
  const [descriptionExpanded, setDescriptionExpanded] = useState(true)
  const [isExpandable, setIsExpandable] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)

  const engDescription = englishContent.find(
    (translatedEntity) => translatedEntity.id === entity.id
  )?.description

  useEffect(() => {
    if (divRef.current !== null) {
      if (divRef.current.clientHeight > threshold) {
        setDescriptionExpanded(false)
        setIsExpandable(true)
      } else {
        setIsExpandable(false)
      }
    }
  }, [])

  const toggleDescription = () => {
    setDescriptionExpanded(!descriptionExpanded)
  }

  let updatedAtString = ''
  if (entity.updatedAt) {
    const updatedAt = new Date(entity.updatedAt)
    const fullYear = updatedAt.getFullYear()
    const month = updatedAt.getMonth() + 1
    updatedAtString = `${fullYear}-${month}`
  }

  return (
    <div className={classes.wrapper}>
      <div
        ref={divRef}
        className={classes.descriptionContainer}
        style={
          descriptionExpanded ? customStyle.expandedDescription : customStyle.notExpandedDescription
        }
      >
        <div
          className={classes.leadText}
          dangerouslySetInnerHTML={{
            __html: getStringAsRichText(lang === 'eng' ? engDescription : entity.description) ?? ''
          }}
        />
        {!descriptionExpanded ? (
          <div className={classes.bottomGradient} />
        ) : (
          <>
            <EntityReferences references={references} className={classes.references} />
            <EntityLinksList
              entity={entity}
              label="Popkulturális Blog"
              path="blog_links"
              className={classes.links}
            />
            <EntityLinksList
              entity={entity}
              label="Kapcsolódó"
              path="related_links"
              className={classes.links}
            />
          </>
        )}
      </div>
      {isExpandable ? (
        <p className={classes.toggleBtn}>
          <span className={classes.toggleBtnText} onClick={toggleDescription}>
            {lang === 'eng' && <span> Show {descriptionExpanded ? 'less' : 'more'} </span>}
            {!lang && <span> Mutass {descriptionExpanded ? 'kevesebbet' : 'többet'} </span>}
          </span>
        </p>
      ) : null}
      <div className={classes.updatedAt}>
        {lang === 'eng' ? (
          <>{updatedAtString && 'Last update: ' + updatedAtString}</>
        ) : (
          <>{updatedAtString && 'Frissítve: ' + updatedAtString} </>
        )}
        {updatedAtString && entity.author && entity.author.name && ', '}
        {entity.author && entity.author.name !== '' && entity.author.name}
      </div>
    </div>
  )
}
