import React, { RefObject, useState } from 'react'
import Plyr from 'plyr'

import { Connection } from '@mpe/api-client/models/connections/connection'
import { Entity, IEntity } from '@mpe/api-client/models/entities/entity'
import { Audio } from '@mpe/api-client/models/entities/audio'
import { Band } from '@mpe/api-client/models/entities/band'

import Cover from '@/components/Cover'
import Description from '@/components/Description/Description'
import VideoSegments from '@/components/VideoSegments/VideoSegments'
import QuickFacts from '@/components/QuickFacts'

import { useAppDispatch } from '@/store/index'
import { scrollTo } from '@/utils'
import { fail, showToaster } from '@/store/slices/toasterSlice'
import { StaticEntity } from '@/consts/types'

import SongLyrics from '../SongLyrics'
import { Song } from '../SongLyrics/types'
import TilesHeading from '../TilesHeading'
import Loader from '../Loader'
import Tiles from '../Tiles'
import { Link } from '../Link/Link'
import { getToken } from '@/store/thunks/videoPlaylistsThunk'
import { Theme } from 'pages/[...entity]'
import AlbumTracklist from '../AlbumTracklist/AlbumTracklist'

interface CoverProps {
  entity: Entity<any>
  mediaConnections: Connection[]
  referenceConnections: Connection[]
  connectedAudios: Audio[]
  connectionsCount: any
  onThemeChipClick: (value: Theme) => void
  topRef: RefObject<any>
}

const classes = {
  listItem: 'flex items-center my-5',
  factIcon: 'w-6 h-6 xl:w-8 xl:h-8 mr-4'
}

export default function EntityDetails({
  entity,
  mediaConnections,
  referenceConnections,
  connectedAudios,
  connectionsCount,
  topRef,
  onThemeChipClick
}: CoverProps) {
  const [videoPlayer, setVideoPlayer] = useState<Plyr | null>(null)
  const [recommendations, setRecommendations] = useState<Entity<any>[] | null>(null)
  const [recommendationsLoading, setRecommendationsLoading] = useState(false)
  const dispatch = useAppDispatch()

  let songs: Song[] = []
  let url = ''

  try {
    if (entity instanceof Band) {
      songs = entity.metadata?.zeneszoveg?.songs || []
      url = entity.metadata?.zeneszoveg?.url || ''
    }
  } catch (e) {}

  const fetchRecommendations = async () => {
    setRecommendations(null)
    setRecommendationsLoading(true)
    const token = getToken()
    const id = entity.id

    try {
      scrollTo(document.getElementById('quickfacts') as HTMLElement)

      const response = await fetch(
        `/api/recommendations/${id}`,
        token
          ? {
              method: 'GET',
              headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`
              }
            }
          : undefined
      )

      const recommendations: any = await response.json()
      if (recommendations.length) {
        setRecommendations(recommendations)
      }
    } catch (error) {
      dispatch(showToaster(fail, 'Hiba az ajánlások betöltése során'))
    }

    setRecommendationsLoading(false)
  }

  const isAudioOrVideo = entity.type === 'Audio' || entity.type === 'Video'

  const LoadRecommendationsButton = () =>
    isAudioOrVideo ? (
      <li className={`${classes.listItem} cursor-pointer flex-col`} onClick={fetchRecommendations}>
        <div className="flex items-center justify-center">
          <div className={`w-8 h-8 bg-openai bg-contain ${recommendationsLoading ? 'spin' : ''}`} />
          <span className="md:hidden ml-2 font-bold text-yellow">Neked ajánljuk</span>
        </div>
        <div className="hidden md:block text-yellow">Neked ajánljuk</div>
      </li>
    ) : (
      <></>
    )

  const renderRecommendationsListHeader =
    isAudioOrVideo && (recommendationsLoading || recommendations)

  const hasRecommendations = !!(recommendations || []).length

  return (
    <>
      <Cover entity={entity} mediaConnections={mediaConnections} setVideoPlayer={setVideoPlayer} />
      <div className="px-4 md:px-10 my-6">
        <QuickFacts
          connectionsCount={connectionsCount}
          onThemeChipClick={onThemeChipClick}
          elementsBefore={<LoadRecommendationsButton />}
        />
      </div>
      <div>
        <Description entity={entity} references={referenceConnections} />

        {!!songs.length && <SongLyrics songs={songs} url={url} />}

        {renderRecommendationsListHeader && (
          <div className="-mt-36">
            <TilesHeading title="Neked ajánljuk" topRef={topRef} />
          </div>
        )}
        {recommendationsLoading && (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        )}
        {renderRecommendationsListHeader && !recommendationsLoading && hasRecommendations && (
          <>
            <Tiles
              pagesCount={1}
              initialEntities={recommendations as (IEntity | StaticEntity)[]}
              topRef={topRef}
              forcedLayout={[0, 0, 0]} // 0: wide
            />
            <div className="text-right mt-2">
              <span className="text-lightGray">
                Powered by{' '}
                <Link href="http://openai.com" className="hover:text-white" target="_blank">
                  ChatGPT
                </Link>
              </span>
            </div>
          </>
        )}
        {renderRecommendationsListHeader && !recommendationsLoading && !hasRecommendations && (
          <div>Sajnos most nem tudtunk tartalmat ajánlani. Kérjük próbáld meg később!</div>
        )}

        <VideoSegments entity={entity} videoPlayer={videoPlayer} />
        <AlbumTracklist entity={entity} tracks={connectedAudios} />
      </div>
    </>
  )
}
