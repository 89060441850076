import { ChangeEvent, RefObject, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { navigationSelector } from '@/store/slices/navigationSlice'

import Icon from '../Icon'

interface TilesHeadingProps {
  id?: string
  topRef: RefObject<any>
  title: string
  subtitle?: string
  selector?: string
  onSelectorChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  selectorOptions?: { [key: string]: string }
  children?: ReactNode
  hideMarginTop?: boolean
}

const TilesHeading = ({
  id = 'tiles-heading',
  topRef,
  title,
  subtitle,
  selector,
  onSelectorChange,
  selectorOptions,
  children,
  hideMarginTop
}: TilesHeadingProps) => {
  const { themesVisibility } = useSelector(navigationSelector)
  return (
    <div
      id={id}
      className={`transition-all tiles-heading md:flex items-center mb-10 mt-16 mr-auto ${
        hideMarginTop ? 'md:mt-0' : 'md:mt-48'
      }`}
      ref={topRef}
      style={{ scrollMarginTop: themesVisibility ? '12rem' : '8rem' }}
    >
      <h2 className="text-3xl lg:text-5xl font-bold tracking-wide mr-auto my-0 mb-4 md:mb-0">
        {title}
        {subtitle && <div className="text-sm font-normal">{subtitle}</div>}
      </h2>
      {children}
      {onSelectorChange && selectorOptions && (
        <div className="flex relative md:ml-5">
          <select
            className="appearance-none bg-black rounded-full pl-5 pr-10 py-2 border-white border-2 focus:outline-none w-full cursor-pointer"
            onChange={onSelectorChange}
            value={selector}
          >
            {Object.keys(selectorOptions).map((key) => (
              <option value={key} key={key}>
                {selectorOptions[key]}
              </option>
            ))}
          </select>
          <Icon name="caret" className="absolute w-4 right-4 top-5" />
        </div>
      )}
    </div>
  )
}

export default TilesHeading
