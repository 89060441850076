import Plyr from 'plyr'
import { Duration } from 'luxon'
import { Entity } from '@mpe/api-client/models/entities/entity'

import useDataLayer from '@/hooks/useDataLayer'
import { scrollToTop } from '@/utils'
import { useSelector } from 'react-redux'
import { videoPlayerSelector } from '@/store/slices/videoPlayerSlice'

interface VideoSegmentsProps {
  entity: Entity<any>
  videoPlayer: Plyr | null
}

export default function VideoSegments({ entity, videoPlayer }: VideoSegmentsProps) {
  const videoPlayerSel = useSelector(videoPlayerSelector)

  const jumpToSegment = (startAt: number) => {
    if (!videoPlayer) {
      return
    }

    videoPlayer.currentTime = startAt
    videoPlayer.play()

    useDataLayer().push({
      event: 'video-segment',
      value: startAt,
      title: entity.name,
      entityId: entity.id
    })
  }

  if (!entity.segments?.length) {
    return null
  }

  return (
    <div className="px-4 md:px-10 my-6">
      <h2>Videóban szereplő számok listája</h2>

      <ul className="list-disc list-inside mt-3">
        {entity.segments
          .sort((a, b) => a.startAt - b.startAt)
          .map((s, i) => (
            <li
              className={`cursor-pointer hover:underline ${
                i === videoPlayerSel.currentSegment - 1 && 'font-black'
              }`}
              key={s.id}
              onClick={() => {
                scrollToTop()
                jumpToSegment(s.startAt)
              }}
            >
              {Duration.fromMillis(s.startAt * 1000).toFormat('hh:mm:ss')}: {s.name}
            </li>
          ))}
      </ul>
    </div>
  )
}
