import { SongLyricsProps } from './types'

const classes = {
  wrapper: `p-4 md:pt-10 md:px-14 md:pb-6`,
  inner: `lg:w-3/4 text-lg md:text-xl text-white mx-auto`,
  lyrics: `md:flex-wrap flex-wrap-none flex flex-col md:flex-row -mx-3 mt-4 gap-x-2`,
  bottom: `mt-8 w-full flex`,
  item: `w-fit`,
  songTitle: `text-sm-md hover:bg-darkGray hover:text-skyBlue p-3 block transition`,
  more: `flex ml-auto border-b-2 border-skyBlue md:border-0 cursor-pointer hover:text-skyBlue gap-x-2`,
  moreIcon: `w-10 text-white transition`
}

export default function useSongLyrics(props: SongLyricsProps): any {
  return {
    songs: props.songs || [],
    url: props.url || '',
    classes
  }
}
